import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Heading from './Heading';
import Icon from './Icon';

import '../Styles/Skills.css';

const Skills = ({ setCurrNav, skills }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.4,
    });

    useEffect(() => {
        if (inView) setCurrNav(2);

        // eslint-disable-next-line
    }, [inView]);

    return (
        <section id='skills' ref={ref}>
            <Heading
                index='02.'
                heading="Technical Skills"
            />
            <p className='about-p'>
                The technology skills I usually use in my
                projects are here:{' '}
            </p>
            <div className='skills'>
                {skills?.map((skill, index) => (
                    <div className='skill' key={index}>
                        <Icon
                            icon={skill.icon}
                            iconType={skill.icon_type}
                            className='skill-img'
                        />
                        <span className='skill-name'>
                            {skill.name}
                        </span>
                    </div>
                ))}
            </div>
            <p className='about-p'>
                I'm also currently learning a few new things
                like{' '}
                <span className='skill-learning'>
                    ElectronJS
                </span>
                ,{' '}
                <span className='skill-learning'>
                    NextJS
                </span>
                ,{' '}
                <span className='skill-learning'>
                    Redis
                </span>,{' '}
                <span className='skill-learning'>
                    Rust
                </span>{' '}
                and constantly updating my skills.
            </p>
        </section>
    );
};

export default Skills;
