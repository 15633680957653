import { createPortal } from 'react-dom';

import ScrollContainer from 'react-indiana-drag-scroll';

import '../Styles/ProjectShowcase.css';

import Pill from './Pill';
import RichText from './RichText';

const ProjectShowcase = ({ project, show, setShow }) => {
    return createPortal(
        <div
            className={`modal project-showcase ${show ? 'modal-show' : ''
                }`}
        >
            <div
                className='modal-backdrop'
                onClick={() => setShow(false)}
            ></div>
            <div className='project-large'>
                <div className='pl-head'>
                    <div className='pl-logo-container'>
                        <img
                            src={project.logo}
                            alt={`${project.name}-logo`}
                            className='pl-logo'
                        />
                    </div>
                    <div className='pl-head-desc'>
                        <h1 className='pl-title'>
                            {project.name}
                        </h1>
                        <p className='pl-slug'>
                            {project.slug}
                        </p>
                    </div>
                    <div
                        className='pl-cancel'
                        onClick={() => setShow(false)}
                    >
                        <i className='fas fa-times'></i>
                    </div>
                </div>

                <div className='pl-details'>
                    {(project.link || project.github) && (
                        <div className='pl-links'>
                            {project?.link && (
                                <Pill
                                    text='View'
                                    icon='fas fa-external-link-alt'
                                    link={project.link}
                                />
                            )}
                            {project?.github && (
                                <Pill
                                    text='Github'
                                    icon='fab fa-github'
                                    link={project.github}
                                />
                            )}
                        </div>
                    )}

                    <div className='pl-tech-stack'>
                        <div className='pl-ts-heading'>
                            Technology Stack
                        </div>
                        <div className='pl-ts'>
                            {project?.stack?.map(
                                (ts, index) => (
                                    <Pill
                                        key={index}
                                        text={ts.name}
                                        icon={ts.icon}
                                        iconType={
                                            ts.icon_type
                                        }
                                    />
                                ),
                            )}
                        </div>
                    </div>

                    <ScrollContainer className='pl-demos'>
                        {project?.slideshow?.length
                            ? project?.slideshow?.map(
                                (demo, index) => (
                                    <div
                                        className='pl-demo'
                                        key={index}
                                    >
                                        {demo.type ===
                                            'video' ? (
                                            <video
                                                className='pl-demo-media pl-demo-video'
                                                controls
                                            >
                                                <source
                                                    src={
                                                        demo.src
                                                    }
                                                    type={`video/${demo.src.substr(
                                                        demo.src
                                                            .length -
                                                        3,
                                                    )}`}
                                                />
                                            </video>
                                        ) : (
                                            <img
                                                src={demo.src}
                                                className='pl-demo-media pl-demo-image'
                                                alt={demo.title}
                                            />
                                        )}
                                    </div>
                                ),
                            )
                            : (
                                <div className='pl-demo pl-demo-single'>
                                    <img
                                        src={project.cover}
                                        className='pl-demo-media pl-demo-image'
                                        alt={project.name}
                                    />
                                </div>
                            )
                        }
                    </ScrollContainer>

                    <div className='pl-description'>
                        <div className='pl-d-heading'>
                            Description
                        </div>
                        <p className='pl-slug pl-d'>
                            <RichText
                                text={project.description}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('modal'),
    );
};

export default ProjectShowcase;
