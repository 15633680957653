import '../Styles/Loader.css';

const Loader = ({show = false}) => (
    <div className={`preloader ${show ? 'show' : ''}`}>
        <div className='preloader__box'>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
);

export default Loader;
