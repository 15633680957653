import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import Loader from './Components/Loader';
import ProjectShowcase from './Components/ProjectShowcase';
import Hero from './Components/Hero';
import About from './Components/About';
import Skills from './Components/Skills';
import Projects from './Components/Projects';
import Experiences from './Components/Experiences';
import ContactMe from './Components/ContactMe';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Socials from './Components/Socials';

import './App.css';

const App = () => {
    const [project, setProject] = useState({});
    const [show, setShow] = useState(false);
    const [dark, setDark] = useState(true);
    const [currNav, setCurrNav] = useState(0);

    // Notion
    const [loading, setLoading] = useState(false);
    const [notionData, setNotionData] = useState(null);

    const fetchNotionData = async () => {
        setLoading(true);

        const apiUrl = `${process.env.REACT_APP_API
                ? process.env.REACT_APP_API
                : ''
            }/api/notion`;

        fetch(apiUrl)
            .then(async (response) => {
                const body = await response.json();
                setNotionData(body);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        let theme =
            window.localStorage.getItem('darkTheme');
        if (theme === null) {
            const userPrefersDark =
                window.matchMedia &&
                window.matchMedia(
                    '(prefers-color-scheme: dark)',
                ).matches;

            setDark(userPrefersDark);

            window.localStorage.setItem('darkTheme', true);
        } else {
            setDark(theme === 'true');
        }

        fetchNotionData();
    }, []);

    useEffect(() => {
        if (!dark) {
            document.documentElement.classList.add('light');
            window.localStorage.setItem('darkTheme', false);
        } else {
            document.documentElement.classList.remove(
                'light',
            );
            window.localStorage.setItem('darkTheme', true);
        }
    }, [dark]);

    return (
        <div className='App'>
            <Toaster />
            <ProjectShowcase
                show={show}
                setShow={setShow}
                project={project}
            />
            <Loader show={loading} />
            {!loading && (
                <div className='main'>
                    <Navbar
                        currNav={currNav}
                        setCurrNav={setCurrNav}
                    />
                    <Hero
                        currNav={currNav}
                        setCurrNav={setCurrNav}
                    />
                    <About
                        currNav={currNav}
                        data={notionData?.about_me}
                        setCurrNav={setCurrNav}
                    />
                    <Skills
                        currNav={currNav}
                        setCurrNav={setCurrNav}
                        skills={notionData?.skills}
                    />
                    <Projects
                        currNav={currNav}
                        projects={notionData?.projects}
                        setShow={setShow}
                        setProject={setProject}
                        setCurrNav={setCurrNav}
                    />
                    <Experiences
                        currNav={currNav}
                        experiences={
                            notionData?.experiences
                        }
                        setCurrNav={setCurrNav}
                    />
                    <ContactMe
                        dark={dark}
                        currNav={currNav}
                        setCurrNav={setCurrNav}
                    />
                    {/* <Resume /> */}
                    <Socials
                        dark={dark}
                        setDark={setDark}
                    />
                    <Footer />
                </div>
            )}
        </div>
    );
};

export default App;
